<template>
  <div>
    <el-card>
      <el-form ref="form" :inline="true">
        <el-form-item label="时间">
          <el-date-picker
            type="datetimerange"
            v-model="times"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="toQuery" icon="el-icon-search"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="integralList"
        stripe
        class="table"
        ref="userTable.multipleSelection"
        header-cell-class-name="table-header"
        border
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column label="用户名" prop="user.nickname" align="center"></el-table-column>
        <el-table-column label="连续打卡天数" prop="days" align="center">
        </el-table-column>
        <el-table-column label="剩余金币" prop="surplus" align="center"> </el-table-column>
        <el-table-column label="总金币" prop="total" align="center">
        </el-table-column>
        <el-table-column label="时间" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.update_time !== null">
              {{
                $moment(scope.row.update_time * 1000).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </div>
            <div v-else>空</div>
          </template>
        </el-table-column>
       

        <el-table-column label="操作" align="center" >
          <template slot-scope="scope">
            <el-button type="success" size="small" icon="el-icon-info" circle @click="toDetails(scope.row)"></el-button>
          </template>
        </el-table-column>
      </el-table>

       <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.page"
        :page-sizes="[3, 5, 8, 10]"
        :page-size="queryForm.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>

     <!-- 详情弹框 -->
    <el-dialog
      :visible.sync="detailsDialog"
      title="详情"
      append-to-body
      width="50%"
      :close-on-click-modal="false"
    >
      <el-form :model="detailsData" ref="details">
        <el-form-item el-form-item label="用户ID：" label-width="100px">
          {{detailsData.user_id}}
        </el-form-item>
        <el-form-item el-form-item label="用户名：" label-width="100px">
          {{detailsData.nickname}}
        </el-form-item>
        <el-form-item el-form-item label="连续打卡：" label-width="100px">
          {{detailsData.days}}
        </el-form-item>
        <el-form-item el-form-item label="总打卡数：" label-width="100px">
          {{detailsData.total}}
        </el-form-item>
         <el-form-item el-form-item label="时间：" label-width="100px">
           {{$moment(detailsData.create_time * 1000).format("YYYY-MM-DD HH:mm:ss")}}
        </el-form-item>
      </el-form>
    </el-dialog>

    </el-card>
  </div>
</template>

<script>
import store from "@/store";
import {IntegralList} from '@/api/pointslist.js'
import { warningDialog } from "@/utils/dialog";
import { log } from 'util';

export default {

  data() {
    return {
      queryForm:{
          page:1,
          limit:10,
          start_time:null,
          end_time:null
      },
      integralList:[],
      total:0,
      times:null,
      detailsDialog:false,
      detailsData:{}
    };
  },

    created(){
        this.getIntegralList()
    },
  methods: {
        //   获取金币列表
    getIntegralList(){
        IntegralList(this.queryForm).then(res=>{
            if (res.code!==1) {
                this.$message.error(res.msg)
                return
            }
            this.total=res.data.total
            this.integralList=res.data.data

        })
    },
    handleSizeChange(size) {
        this.queryForm.limit = size;
        this.getIntegralList();
    },
    handleCurrentChange(page) {
        this.queryForm.page = page;
        this.getIntegralList();
    },

    //  查询
    toQuery(){
        this.queryForm.page=1
        if (this.times) {
            this.queryForm.start_time = new Date(this.times[0]).getTime() / 1000;
            this.queryForm.end_time = new Date(this.times[1]).getTime() / 1000;
        }else if(this.times==null){
            this.queryForm.start_time =null;
            this.queryForm.end_time =null;
        }
        this.getIntegralList()
    },

    // 点击详情
    toDetails(item){
        this.detailsData=item
        if (item.user!==null) {
          this.detailsData.nickname=item.user.nickname
        }
        this.detailsDialog=true
    },

  },
  
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

.add {
  position: fixed;
  /*align-self: flex-end;*/
  right: 130px;
}

.refresh {
  /* position: fixed; */
  /*align-self: flex-end;*/
  /* right: 80px; */
  float: right;
}

.edit {
  margin-left: 50px;
  width: 100px;
  margin-top: 30px;
}

.editUserItemLeft {
  padding-left: 15px;
  margin-top: 5px;
}

.editUserItem {
  margin-bottom: 10px;
  padding-left: 15px;
}
.el-tag {
  cursor: pointer;
}
.pagination {
  display: flex;
  justify-content: space-between;
}
.detail-box{
  height: 750px;
}
.detail{
  width: 100%;
  height: 80px;
  display: flex;
  margin-bottom: 30px;
}
.detail .detail-img{
  flex: 2;
}
.detail-img img{
  width: 82px;
  height: 82px;
  border-radius: 50%;
}
.detail .detail-one{
  flex: 4;
}
.detail .detail-two{
  flex: 5;
}
.detail .detail-three{
  flex: 3;
}
.detail p{
  line-height: 28px;
  color: #323232;
  font-size: 16px;
  font-weight: 500;
}
.detail-box .tabs{
  border-bottom: 2px solid #38A28A;
}
.detail-box .tabs .el-button{
  width: 160px;
  height: 50px;
  border-radius: 0;
}
.detail-box .detail-content{
  height: 504px;
  background-color: #F0F0F0;
}
.jurisdiction{
  height: 700px;
  border-top: 1px solid #ccc;
}
.jurisdiction .jurisdiction-content{
  height: 600px;
  overflow: auto;
}
.jurisdiction .jurisdiction-confirm{
  text-align: center;
  height:100px;
  line-height: 100px;
  margin: auto;
  border-top: 1px solid #ccc;
}
.jurisdiction .jurisdiction-confirm .el-button{
  width: 90px;
  height: 40px;
}
.desc{
    width: 180px;
    height: 112px;
    overflow: hidden;
}
.desc img{
  width: 100%;
  height: 100%;
}
.generate {
  float: right;
}
.file-name{
  /* display: inline-block; */
  width: 100%;
  margin-left: 10px;
  margin-right: 20px;
  white-space: nowrap;
	overflow: hidden;
}
.imgSelect img{
  width: 300px;
  height: 200px;
  cursor: pointer;

}
.videoSelect video{
  width: 300px;
  height: 200px;
  cursor: pointer;
}
::v-deep .el-pager li.active{
  color: #FF6688;
}
</style>
