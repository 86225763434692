import request from "@/utils/request";

// 获取积分列表
export function IntegralList(params){
    return request({
        url:'/admin/base/goldList',
        method: 'get',
        params
        }
    )
}